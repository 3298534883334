.custom-file-upload {
  width: fit-content;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;
}
.custom-file-upload:hover {
  border-color: #6484ec;
  color: #6484ec;
}
.custom-error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
